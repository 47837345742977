// Import Core Clean Blog SCSS
@import "../assets/vendor/startbootstrap-clean-blog/scss/clean-blog.scss";

@import url('https://fonts.googleapis.com/css2?family=Buenard&family=Didact+Gothic&family=Anonymous+Pro&display=swap');

$primary-font: Buenard;
$secondary-font: Didact Gothic;
$monospace-font: Anonymous Pro, monospace;

$header-font: $secondary-font;
$nav-font: $secondary-font;
$body-font: $primary-font;

header.masthead {
  .overlay {
    //background-color: #a8c3eb;
  }
  .page-heading,
  .post-heading,
  .site-heading {
    padding: 200px 0 50px;
    min-height: 300px;

    @media (min-width: 768px) {
      min-height: 500px;
    }
  }
}

#mainNav {
  font-family: $nav-font, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $header-font, sans-serif;
}

body {
  font-family: $body-font, serif;
}

#mainContent {
  p a {
    //color: #8702ff;
    text-decoration: underline;
    //text-decoration: none;
  }
  ul a {
    text-decoration: underline;
    //text-decoration: none;
  }

  strong {
    font-variant: small-caps;
  }

  hr {
    width: 80%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  pre.highlight {
    border: 1px solid;
    margin-left: 5%;
    padding: 3%;
    margin-right: 5%;
    background-color: #fafafa;
    box-shadow: 3px 3px 5px grey;
  }
  code {
    font-family: $monospace-font;
  }
}

header.masthead .page-heading .subheading, header.masthead .site-heading .subheading {
  font-family: 'Buenard', serif;
}

.figure {
  width: 100%;
  text-align: center;
}
.figure-caption {
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
}

blockquote {
  margin-left: 10%;
  margin-right: 10%;
  text-align: justify;
  border-left: 1px solid;
  padding-left: 10px;
}

div.mermaid svg {
  max-width: 100%;
}
